import { z, ZodSchema } from "zod";
import {
  ApiResponse,
  HttpHeaders,
  Path,
  ReqData,
  RequestParams,
} from "~/server/utils";
import { executeAndValidateRequest } from "./utils.client";

const IdDataSchema = z.object({ id: z.string() });
type IdData = z.infer<typeof IdDataSchema>;
const UuidDataSchema = z.object({ uuid: z.string() });
type UuidData = z.infer<typeof UuidDataSchema>;
const IgnoreDataSchema = z.unknown().transform(() => undefined);

export const putData = async (
  path: Path,
  data: ReqData,
  expectedCode: number = 201,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<undefined>> => {
  return executeAndValidateRequest(
    "put",
    path,
    data,
    IgnoreDataSchema,
    expectedCode,
    customHeader
  );
};

export const postData = async (
  path: Path,
  data: ReqData,
  expectedCode: number = 201,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<undefined>> => {
  return executeAndValidateRequest(
    "post",
    path,
    data,
    IgnoreDataSchema,
    expectedCode,
    customHeader
  );
};

export const postDataValidated = async <T>(
  path: Path,
  data: ReqData,
  schema: ZodSchema<T>,
  expectedCode: number = 201,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<T>> => {
  return executeAndValidateRequest(
    "post",
    path,
    data,
    schema,
    expectedCode,
    customHeader
  );
};

export const postDataExpectId = async (
  path: Path,
  data: ReqData,
  expectedCode: number = 201,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<IdData>> => {
  return postDataValidated(
    path,
    data,
    IdDataSchema,
    expectedCode,
    customHeader
  );
};

export const postDataExpectUUID = async (
  path: Path,
  data: ReqData,
  expectedCode: number = 201,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<UuidData>> => {
  return postDataValidated(
    path,
    data,
    UuidDataSchema,
    expectedCode,
    customHeader
  );
};

export const patchData = async (
  path: Path,
  data: ReqData,
  expectedCode: number = 200,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<undefined>> => {
  return executeAndValidateRequest(
    "patch",
    path,
    data,
    IgnoreDataSchema,
    expectedCode,
    customHeader
  );
};

export const patchDataValidated = async <T>(
  path: Path,
  data: ReqData,
  schema: ZodSchema<T>,
  expectedCode: number = 200,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<T>> => {
  return executeAndValidateRequest(
    "patch",
    path,
    data,
    schema,
    expectedCode,
    customHeader
  );
};

export const deleteData = async (
  path: Path,
  expectedCode: number = 200,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<undefined>> => {
  return executeAndValidateRequest(
    "delete",
    path,
    undefined,
    IgnoreDataSchema,
    expectedCode,
    customHeader
  );
};

export const deleteDataValidated = async <T>(
  path: Path,
  schema: ZodSchema<T>,
  expectedCode: number = 200,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<T>> => {
  return executeAndValidateRequest(
    "delete",
    path,
    undefined,
    schema,
    expectedCode,
    customHeader
  );
};

export const getDataClient = async <T>(
  path: Path,
  schema: ZodSchema<T>,
  params?: RequestParams,
  expectedCode: number = 200,
  customHeader: HttpHeaders = {}
): Promise<ApiResponse<T>> => {
  return executeAndValidateRequest(
    "get",
    path,
    undefined,
    schema,
    expectedCode,
    customHeader,
    params
  );
};
